<template>
  <div :key="once" class="page_bg">
    <van-skeleton title avatar :row="23" :loading="loading">
      <!-- 返回和客服、收藏按钮 -->
      <div class="pro_del_banck_cart flex-r-sb-c">
        <van-image width="0.8rem" height="0.8rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/top_back_round.png" fit="fill"
                   @click="backPath"
        />
        <div v-if="proDetailInfo.showPrice === '0'">
          <van-image v-if="parseInt(proDetailInfo.isCollection) === 0" width="0.8rem" height="0.8rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_favor0.png" fit="fill"
                     @click="doClickFavor(true)"
          />
          <van-image v-else width="0.8rem" height="0.8rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_favor1.png" fit="fill"
                     @click="doClickFavor(false)"
          />
          <van-image width="0.8rem" height="0.8rem" style="margin-left: 0.2rem;"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_kefu.png" fit="fill"
                     @click="openKeFu"
          />
        </div>
        <!-- <van-badge :content="$store.getters.totalShopping" color="#EA5C36">
          <van-image width="0.8rem" height="0.8rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/top_cart_round.png" fit="fill"
                     @click="$router.push('/cart/cart')"
          />
        </van-badge> -->
      </div>
      <!-- 商品产品图 -->
      <div class="pro_del_banner">
        <van-swipe class="my-swipe" autoplay="false" style="height:7.49rem">
          <van-swipe-item v-for="(banner,index_banner) in proDetailInfo.proPrcUrls2" :key="index_banner">
            <template v-if="index_banner === 0 && proDetailInfo.videoCode && proDetailInfo.videoUrl">
              <tx-video v-if="showVideo" ref="txVideo" :options="videoOptions" />
            </template>
            <template v-else>
              <van-image width="10rem" height="7.49rem" :src="banner" fit="fill" @click="showPic(index_banner)" />
            </template>
          </van-swipe-item>
        </van-swipe>
        <!-- <van-image v-if="parseInt(proDetailInfo.releaseFlag) === 3" width="10rem" height="0.64rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_detail.png"
        /> -->
        <div v-if="parseInt(proDetailInfo.releaseFlag) === 3" class="presell_box flex-r-sb-c">
          <div class="presell_msg flex-c-s-s">
            <div class="msg_title">预售商品</div>
            <div class="msg_tips">商品采购中，预计7-14天内到仓即可发货</div>
          </div>
          <div class="presell_bottom flex-r-c-c" @click="orderPresell">
            <van-image width="0.32rem" height="0.32rem" style="margin-right:0.1rem" :src="is_presell ? 'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_1.png'
              :'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_0.png'"
            />
            {{ is_presell ? '已订阅':'到货提醒' }}
          </div>
        </div>
      </div>
      <!-- 产品主要信息 -->
      <div class="pro_del_info">
        <!-- 秒杀信息 -->
        <div v-if="proDetailInfo.activity.mallFlashDetailRes" class="info_seckill flex-r-sb-c">
          <div class="seckill_left">
            <van-image width="0.53rem" height="0.53rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_seckill.png"
            />
            限时秒杀
          </div>
          <div class="seckill_right">
            <van-count-down :time="seckillTime" format="距离结束还剩 DD天HH:mm:ss" style="color:#fff;font-size:0.32rem" />
          </div>
        </div>
        <!-- 基础信息 -->
        <div class="info_name van-multi-ellipsis--l2 flex-r-s-c">
          <van-image v-if="isShowYanxuan" width="0.75rem" height="0.43rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/vip_pro_tag.png"
          />
          {{ proDetailInfo.proName }}
        </div>
        <div class="info_price_salenNum flex-r-sb-c">
          <div v-if="proDetailInfo.proPrice" class="info_price_new">
            <van-image v-if="proDetailInfo.activity.mallFlashDetailRes" width="0.96rem" height="0.37rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_seckill_price.png"
            />
            <template v-if="timeLimitActivityType && !isVip">
              ￥<span
                style="font-size: 0.52rem;"
              >{{ $utils.moneyDote(proDetailInfo.freshmanActivityProduct.price)[0] }}</span>.{{ $utils.moneyDote(proDetailInfo.freshmanActivityProduct.price)[1] }}
            </template>
            <template v-else-if="isVip">
              <div class="flex-r-s-c">
                <van-image width="0.91rem" height="0.37rem"
                           src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/vip/pro_vip_price.png"
                />
                <div style="margin-left: 0.1rem;">
                  <span
                    style="font-size: 0.52rem;"
                  >{{ $utils.moneyDote(proDetailInfo.proPrice)[0] }}</span>.{{ $utils.moneyDote(proDetailInfo.proPrice)[1] }}
                </div>
              </div>
              <div style="text-decoration:line-through;color: #999999;font-size: 0.37rem;">
                原价：￥{{ proDetailInfo.originPrice }}
              </div>
            </template>
            <template v-else>
              ￥<span
                style="font-size: 0.52rem;"
              >{{ $utils.moneyDote(proDetailInfo.proPrice)[0] }}</span>.{{ $utils.moneyDote(proDetailInfo.proPrice)[1] }}
            </template>
          </div>
          <div v-if="proDetailInfo.minQuantity > 1" class="info_salenNum">起售量 {{ proDetailInfo.minQuantity }}
            {{ proDetailInfo.unitName || '个' }}</div>
        </div>
        <div v-if="proDetailInfo.formType === 3" class="limit_er_tip flex-r-s-c">
          <div class="num_store">剩余 {{ proDetailInfo.storeTotal }} 个</div>
        </div>
      </div>
      <!-- 分期信息 -->
      <div v-if="proDetailInfo.paymentDayStatus === 1" class="pro_del_info2 flex-c-s-s">
        <div class="info2_item flex-r-s-s" style="border:none">
          <div class="info2_item_label">商品分期</div>
          <div class="info2_item_stages flex-r-sb-c">
            <div class="stages_box flex-r-s-c">
              <div class="stages_title">分期</div>
              <div class="stages_contant">￥{{ proDetailInfo.paymentAmount }} X {{ proDetailInfo.paymentNum }}期</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品优惠信息 -->
      <template v-if="!proDetailInfo.activity.mallFlashDetailRes && !timeLimitActivityType && !isVip">
        <div v-if="proDetailInfo.activity.activityActionDetailRes.length > 0 || proDetailInfo.activity.mallFlashDetailRes ||proDetailInfo.activity.productReturnCouponItemRes "
             class="pro_del_coupon pro_del_info2  flex-c-s-s"
        >
          <div class="info2_item flex-r-s-s">
            <div class="info2_item_label">商品优惠</div>
            <!--  返券  满减 满赠-->
            <div class="flex-c-s-s" @click="openActivity">
              <div v-if="proDetailInfo.activity.productReturnCouponItemRes"
                   class="info2_item_value info2_item_coupon flex-r-s-c"
              >
                <van-image width="1.07rem" height="0.59rem"
                           src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_01.png"
                           fit="fill"
                />
                <div class="coupon_name van-ellipsis">返{{ proDetailInfo.activity.productReturnCouponItemRes.money }}元现金券
                </div>
              </div>
              <template v-if="proDetailInfo.activity.activityActionDetailRes.length > 0">
                <div v-for="(activityActionDetailRes,activityActionDetailRes_index) in proDetailInfo.activity.activityActionDetailRes"
                     :key="activityActionDetailRes_index" class="info2_item_value info2_item_coupon flex-r-s-c"
                >
                  <van-image v-if="activityActionDetailRes.actionType === 'MJ_AMOUNT_AC'" width="1.07rem"
                             height="0.59rem"
                             src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_02.png"
                             fit="fill"
                  />
                  <van-image v-if="activityActionDetailRes.actionType === 'ZS_SKU_NUM_AC'" width="1.07rem"
                             height="0.59rem"
                             src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_03.png"
                             fit="fill"
                  />
                  <div class="coupon_name van-ellipsis">{{ activityActionDetailRes.name }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
      <!-- 产品其他信息 -->
      <div class="pro_del_info2 flex-c-s-s">
        <div class="info2_item flex-r-s-s" @click="brand_ser_click(proDetailInfo.proBrand)">
          <div class="info2_item_label">商品品牌</div>
          <div class="info2_item_value flex-r-sb-c">
            <span>{{ proDetailInfo.proBrand }}</span>
            <van-icon name="arrow" color="#999999" style="margin-right:0.37rem" />
          </div>
        </div>
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">规格型号</div>
          <div class="info2_item_value">{{ proDetailInfo.proModel }}</div>
        </div>
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">商品效期</div>
          <div class="info2_item_value">{{ proDetailInfo.validityPeriod }} (仅做参考)</div>
        </div>
        <div v-if="proDetailInfo.suggestPrice" class="info2_item flex-r-s-s">
          <div class="info2_item_label">建议零售价</div>
          <div class="info2_item_value">￥{{ proDetailInfo.suggestPrice }}</div>
        </div>
        <!-- <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">经销商</div>
          <div class="info2_item_value">兽医首选</div>
        </div>
        <div class="info2_item flex-r-s-s">
          <div class="info2_item_label">商家服务</div>
          <div class="info2_item_value">付款后24h内发货丨正品保障</div>
        </div> -->
      </div>
      <!-- 相关商品 -->
      <div v-if="proDetailInfo.unionItemResDtos.length>0" class="pro_like flex-c-s-s">
        <div class="like_title">相关商品</div>
        <div class="like_lists flex-r-s-s">
          <div v-for="(like,like_index) in proDetailInfo.unionItemResDtos" :key="like_index" class="like_list"
               @click="reLoadPro(like.otherId)"
          >
            <van-image width="2.93rem" height="2.19rem" :src="like.proIconUrl" />
            <div class="like_list_name van-ellipsis">{{ like.proName }}</div>
          </div>
        </div>
      </div>
      <!-- 产品相关详情介绍图 -->
      <div class="pro_del_imgs">
        <van-image v-for="(img01,index_img01) in imgView" :key="index_img01" width="10rem" :src="img01" fit="fill"
                   style="margin-bottom:-0.21rem;margin-top:-0.22rem" @click="showDetailPic(index_img01)"
        />
      </div>
    </van-skeleton>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
    <!-- 底部提交bar -->
    <proDel v-if="!isVip" ref="cart" :from="1" :info="pid" :company-id="companyId" :show-price="proDetailInfo.showPrice"
            :min-quantity="proDetailInfo.minQuantity" :onoff="proDetailInfo.releaseFlag"
            :activty="proDetailInfo.activtyType" :collect="proDetailInfo.isCollection"
            :buy-num="proDetailInfo.activtyBuyNum" :fash-pro-num="proDetailInfo.quantity" :is-seckill="isSeckill"
            :newuser="timeLimitActivityType" @addCart="openCart"
    />
    <!-- vip 底部 bar -->
    <proDelVip v-if="isVip" ref="cart" :info="pid" :company-id="companyId" :price="proDetailInfo.proPrice"
               :status="proDetailInfo.receiveFlag"
    />
    <!-- 底部弹框 -->
    <van-action-sheet v-model="maskShow" title="采购数量">
      <div class="mask_content flex-r-s-c">
        <van-image width="3.41rem" height="2.56rem" :src="proDetailInfo.proPrcUrls2[0]" />
        <div class="mask_pro_info flex-c-s-s">
          <div class="pro_price">
            <template v-if="timeLimitActivityType">
              ￥<span> {{ proDetailInfo.freshmanActivityProduct.price }} </span>
            </template>
            <template v-else>
              ￥<span> {{ proDetailInfo.proPrice }} </span>
            </template>
          </div>
          <div class="pro_name van-multi-ellipsis--l2">{{ proDetailInfo.proName }}</div>
          <div class="pro_other van-multi-ellipsis--l2">{{ proDetailInfo.proBrand }} | {{ proDetailInfo.proModel }}
          </div>
        </div>
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">采购数量</div>
        <van-stepper v-model="value" :min="min" :max="max" :step="step" integer disable-input theme="round"
                     button-size="22" @change="onChange"
        />
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">总计</div>
        <div class="total_price">￥ <span style="font-size: 0.53rem;">{{ totalProPrice }}</span></div>
      </div>
      <van-divider />
      <div class="mask_pro_btn flex-r-c-c">
        <div class="right_btn flex-r-s-s">
          <div class="right_btn_cart" @click="addCart">加入购物车</div>
          <div class="right_btn_pay" @click="subPro">立即采购</div>
        </div>
      </div>
    </van-action-sheet>
    <!-- 优惠信息弹框 -->
    <van-overlay :show="activityShow" @click="activityShow = false">
      <div class="overlay_activity flex-c-s-c">
        <van-image width="100%" height="2.4rem" src="https://oss.xiaoyi120.com/shop2.0/product/pro_activity_bg.png"
                   fit="fill" class="overlay_activity_img"
        />
        <div class="overlay_activity_box flex-c-c-s">
          <!-- 返券 -->
          <div v-if="proDetailInfo.activity.productReturnCouponItemRes" class="info2_item_value flex-r-s-c">
            <van-image width="1.07rem" height="0.59rem"
                       src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_01.png"
                       fit="fill"
            />
            <div class="coupon_name">
              返{{ proDetailInfo.activity.productReturnCouponItemRes.money }}元现金券
            </div>
          </div>
          <!-- 满减 满赠 -->
          <template v-if="proDetailInfo.activity.activityActionDetailRes.length > 0">
            <div v-for="(activityActionDetailRes,activityActionDetailRes_index) in proDetailInfo.activity.activityActionDetailRes"
                 :key="activityActionDetailRes_index" class="info2_item_value flex-r-c-s"
            >
              <van-image v-if="activityActionDetailRes.actionType === 'MJ_AMOUNT_AC'" width="1.07rem" height="0.59rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_02.png"
                         fit="fill"
              />
              <van-image v-if="activityActionDetailRes.actionType === 'ZS_SKU_NUM_AC'" width="1.07rem" height="0.59rem"
                         src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/pro_coupon_03.png"
                         fit="fill"
              />
              <div class="coupon_name">{{ activityActionDetailRes.name }}</div>
            </div>
          </template>
          <!-- 按钮 -->
          <div class="overlay_activity_box_button" @click="activityShow = false"> 知道了 </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import './index.scss'
import { shoppingCart_addUnion } from '@/api/cart'
import {
  mallProduct_mallProductDetailH, user_collection, user_collection_delete, mallProduct_mallProductDetailH_VIP,
  mallProduct_getArrivalReminder, mallProduct_setArrivalReminder
} from '@/api/pro'
import proDel from '@/components/bar/pro_del'
import proDelVip from '@/components/bar/pro_del_vip'
import txVideo from '@/components/cs-txVideo/cs-txVideo'
import kefuBtn from '@/components/kefu_btn'
import { ImagePreview } from 'vant'
export default {
  name: 'ProDetail',
  components: { proDelVip, proDel, txVideo, kefuBtn },
  data() {
    return {
      once: 1,
      onceList: [],
      loading: true,
      pid: 0,
      companyId: '1000',
      videoOptions: { file_id: '387702299824584719', appId: '1304134186' },
      proDetailInfo: {},
      isShowYanxuan: false,
      showVideo: false,
      seckillTime: '',
      isSeckill: false,
      timeLimitActivityType: false,
      isVip: false,
      imgView: [],
      is_presell: false,
      // 优惠信息弹框
      activityShow: false,
      // 底部弹框
      maskShow: false,
      value: '',
      min: 1,
      max: 9999,
      step: 1,
      totalProPrice: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      window.sessionStorage.setItem('lasterRouter', from.path)
    })
  },
  created() {
    if (window.name === '') {
      console.log('首次被加载')
      window.name = 'proDetail' // 在首次进入页面时我们可以给window.name设置一个固定值
      sessionStorage.removeItem('jump')
      sessionStorage.setItem('shareUrl', '/product/detail?productId=' + this.$route.query.productId)
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
    this.$utils.cartNum(this.$store)
    this.pid = this.$route.query.productId
    if (this.$route.query.timeLimitActivityType) {
      this.timeLimitActivityType = true
      sessionStorage.setItem('timeLimitActivityType', this.$route.query.timeLimitActivityType)
    } else {
      sessionStorage.removeItem('timeLimitActivityType')
    }
    const parms = { step: this.once, id: this.pid }
    this.onceList.push(parms)
    this.isVip = this.$route.query.isVip
    if (this.$route.query.isVip) {
      this.getDetail_vip()
    } else {
      this.getDetail()
    }
  },
  methods: {
    backPath() { window.history.back() },
    getDetail() {
      mallProduct_mallProductDetailH(this.pid).then(res => {
        this.proDetailInfo = res.data
        // 判断严选
        // const tagId = parseInt(res.data.tagId)
        // if (tagId === 274 || tagId === 280 || tagId === 281 || tagId === 283 || tagId === 284) { this.isShowYanxuan = true }
        this.isShowYanxuan = res.data.isStrict
        // 添加视频进入swipe
        if (this.proDetailInfo.videoCode && this.proDetailInfo.videoUrl) {
          this.proDetailInfo.proPrcUrls2.unshift(this.proDetailInfo.videoCode)
          this.videoOptions.file_id = this.proDetailInfo.videoCode
        }
        this.imgView = [...res.data.proDetail, ...res.data.proTechnicalParameter, ...res.data.proAfterInformation]
        // 秒杀倒计时
        if (this.proDetailInfo.activity.mallFlashDetailRes) {
          this.isSeckill = true
          var startDate1 = new Date().getTime()
          var endDate1 = new Date(this.proDetailInfo.activity.mallFlashDetailRes.endDate.replace(/-/g, '/')).getTime()
          this.seckillTime = endDate1 - startDate1
        }
        // 判断是否预售预约
        if (parseInt(res.data.releaseFlag) === 3) {
          mallProduct_getArrivalReminder({ mallProductId: this.pid }).then(resx => { this.is_presell = parseInt(resx.data.reminderFlag) === 2 })
        }
        console.log(this.proDetailInfo.proPrcUrls2)
        this.loading = false
        this.showVideo = true
        this.shareDetail()
      })
    },
    getDetail_vip() {
      mallProduct_mallProductDetailH_VIP(this.pid).then(res => {
        this.proDetailInfo = res.data
        // 添加视频进入swipe
        if (this.proDetailInfo.videoCode && this.proDetailInfo.videoUrl) {
          this.proDetailInfo.proPrcUrls2.unshift(this.proDetailInfo.videoCode)
          this.videoOptions.file_id = this.proDetailInfo.videoCode
        }
        // 秒杀倒计时
        if (this.proDetailInfo.activity.mallFlashDetailRes) {
          this.isSeckill = true
          var startDate1 = new Date().getTime()
          var endDate1 = new Date(this.proDetailInfo.activity.mallFlashDetailRes.endDate.replace(/-/g, '/')).getTime()
          this.seckillTime = endDate1 - startDate1
        }
        console.log(this.proDetailInfo.proPrcUrls2)
        this.loading = false
        this.showVideo = true
        this.shareDetail()
      })
    },
    reLoadPro(id) {
      this.once += 1
      const parms = { step: this.once, id: id }
      this.onceList.push(parms)
      this.pid = id
      this.getDetail()
      this.$forceUpdate()
    },
    brand_ser_click(val) {
      this.$router.push('/product/brandList?name=' + val)
      // if (val === '贝思倍健') {
      //   this.$router.push('/product/subjectPage_bsbj')
      // } else {
      //   this.$router.push('/product/brandList?name=' + val)
      // }
    },
    // 预约预售提醒
    orderPresell() {
      if (!this.is_presell) {
        mallProduct_setArrivalReminder({ mallProductId: this.pid }).then(() => {
          this.is_presell = true
          this.$toast({ message: '预约成功！', duration: 1 * 1000 })
        })
      }
    },
    // 预览
    showPic(index) {
      if (this.proDetailInfo.videoUrl) (index + 1)
      ImagePreview({
        images: this.proDetailInfo.proPrcUrls2,
        startPosition: index
      })
    },
    showDetailPic(index) {
      ImagePreview({
        images: this.imgView,
        startPosition: index
      })
    },
    // 收藏
    doClickFavor(status) {
      if (status) {
        user_collection({ productId: this.pid }).then(res => {
          this.proDetailInfo.isCollection = 1
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      } else {
        user_collection_delete(this.pid).then(res => {
          this.proDetailInfo.isCollection = 0
          this.$toast({ message: status ? '收藏成功！' : '取消成功！', duration: 1 * 1000 })
        })
      }
    },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    // =====================================activity===================================
    openActivity() {
      this.activityShow = true
    },
    // ===================================mask========================================
    openCart() {
      if (this.proDetailInfo.showPrice === '1') {
        this.$toast({ message: '面议商品无法添加购物车', duration: 1 * 2000 })
      } else {
        this.maskShow = true
        if (this.proDetailInfo.quantity === null || this.proDetailInfo.quantity === undefined) {
          this.min = this.proDetailInfo.minQuantity
          this.step = this.proDetailInfo.minQuantity
          this.totalProPrice = (parseInt(this.proDetailInfo.minQuantity) * this.proDetailInfo.proPrice * 1).toFixed(2)
        } else {
          this.min = this.proDetailInfo.fashProNum
          this.totalProPrice = (parseInt(this.proDetailInfo.fashProNum) * this.proDetailInfo.proPrice * 1).toFixed(2)
        }
      }
    },
    onChange(value) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.value = value
        this.totalProPrice = (parseInt(value) * this.proDetailInfo.proPrice * 1).toFixed(2)
      }, 500)
    },
    addCart() {
      const parms = {
        companyId: '1000',
        products: [{ productId: this.pid, proNumber: this.value }]
      }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
        this.maskShow = false
      })
    },
    subPro() {
      let url = ''
      url = `/order/subOrder?item=${this.pid}-${this.value}-1000&type=2`
      this.$router.push(url)
    },
    // ---------------------------------- 分享配置 ----------------------------------
    shareDetail() {
      var img = ''
      if (this.proDetailInfo.videoCode) {
        img = this.proDetailInfo.proPrcUrls2[1] || 'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/wholesale/pro_empty_big.png'
      } else {
        img = this.proDetailInfo.proPrcUrls2[0] || 'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/wholesale/pro_empty_big.png'
      }
      this.$wechat.share({
        title: '商品详情',
        img: img,
        desc: `我给你分享了${this.proDetailInfo.proName}，快来看看吧！`
      })
    }
  }
}
</script>
